<template>
    <div>
        <ContextTitle :passedActions="titleBarActions" title="Configs" />
        <v-container fluid>
            <v-card width="100%" class="pa-3">
                <v-row class="mt-3">
                    <v-col md="8" class="pt-0 pb-0">
                        <store-search-new
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="onLocationChange"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 mt-3">
                    <v-col md="3" class="pr-3 pl-1">
                        <v-text-field
                            v-model="searchString"
                            attach
                            @change="debounced_searchChange"
                            label="Search Configs"
                            clearable
                        />
                    </v-col>
                    <v-col md="3" class="pr-3 pl-1">
                        <!-- <v-select
              v-model="selectedPlatformTypes"
              :items="platformTypeOptions"
              item-value="id"
              item-text="text"
              multiple
              attach
              label="Platforms"
              clearable
            /> -->
                    </v-col>
                </v-row>
            </v-card>
            <v-row no-gutters>
                <v-col class="fill-height">
                    <v-row justify="center" class="overflow-y-auto mt-5">
                        <v-col>
                            <h5 v-if="!isWaypointsSelected" class="pt-3">
                                Select a waypoint to start
                            </h5>

                            <h5
                                v-else-if="filteredBandaidFields.length === 0"
                                class="pt-3"
                            >
                                Sorry, no config items matches your query.
                            </h5>
                        </v-col>
                    </v-row>
                    <v-row
                        no-gutters
                        class="mt-0"
                        v-if="
                            bandaidMetadata.fields != undefined &&
                            bandaidMetadata.fields.length > 0 &&
                            configMetadata.fields != undefined &&
                            configMetadata.fields.length > 0 &&
                            waypointId != undefined
                        "
                    >
                        <v-col md="6" sm="8" class="fill-height pr-1">
                            <v-card>
                                <v-card-title>Bandaid Configs</v-card-title>
                                <v-card-text>
                                    <v-col md="12" class="fill-height">
                                        <v-row no-gutters>
                                            <BandaidConfigForm
                                                :loading="
                                                    isMetadataLoading ||
                                                    isConfigLoading ||
                                                    isSaveInProgress
                                                "
                                                v-model="stageBandaidConfig"
                                                :prodValue="bandaidConfigs.prod"
                                                :metadata="bandaidMetadata"
                                                :fields="filteredBandaidFields"
                                                :searchString="searchString"
                                                :selectedPlatformTypes="
                                                    selectedPlatformTypes
                                                "
                                                :disabledKeys="disabledKeys"
                                                env="stage"
                                            />
                                        </v-row>
                                    </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="6" class="fill-height pl-1">
                            <v-card>
                                <v-card-title>Configs (newer)</v-card-title>
                                <v-card-text>
                                    <ConfigForm
                                        :loading="
                                            isMetadataLoading ||
                                            isConfigLoading ||
                                            isSaveInProgress
                                        "
                                        v-model="stageConfig"
                                        :metadata="metadata"
                                        :fields="filteredFields"
                                        :searchString="searchString"
                                        :selectedPlatformTypes="
                                            selectedPlatformTypes
                                        "
                                        :prodValue="configs.prod"
                                        :disabledKeys="disabledKeys"
                                        env="prod"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import ContextTitle from "components/context_title.vue";
import StoreSearchNew from "components/store_search_new";
import { mapActions, mapGetters } from "vuex";
import BandaidConfigForm from "components/bandaid_config_form";
import ConfigForm from "components/config_form";

import clone from "clone";

export default {
    name: "ConfigsIndex",
    data() {
        return {
            titleBarActions: [
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        this.save();
                    },
                },
                {
                    type: "duplicate",
                    display: "Publish To Prod",
                    run: () => {
                        this.publish();
                    },
                },
                {
                    type: "",
                    display: "Refresh",
                    run: () => {
                        this.refresh();
                    },
                },
            ],
            selectedPlatformTypes: [],
            airport: null,
            waypointId: null,
            searchString: "",
            isMetadataLoading: false,
            isConfigLoading: false,
            isSaveInProgress: false,
            stageBandaidConfig: undefined,
            stageConfig: undefined,
            disabledKeys: [
                "IsIG_OAT",
                "IsIG_VK",
                "IsOMNI_OAT",
                "IsOMNI_VK",
                "IsVVN_OAT",
                "IsVVN_VK",
            ],
        };
    },
    components: {
        ContextTitle,
        StoreSearchNew,
        BandaidConfigForm,
        ConfigForm,
    },
    computed: {
        filteredBandaidFields() {
            if (
                this.bandaidMetadata == undefined ||
                this.bandaidMetadata.fields == undefined
            )
                return [];
            let fields = this.bandaidMetadata.fields;
            if (this.searchString !== "" && this.searchString != null) {
                fields = fields.filter((el) =>
                    el.name
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase())
                );
            }
            if (this.selectedPlatformTypes.length > 0) {
                fields = fields.filter((el) => {
                    for (const p of this.selectedPlatformTypes) {
                        if (el.platforms.includes(parseInt(p))) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            return fields;
        },
        filteredFields() {
            if (this.metadata == undefined || this.metadata.fields == undefined)
                return [];
            let fields = this.metadata.fields;
            if (this.searchString !== "" && this.searchString != null) {
                fields = fields.filter((el) =>
                    el.name
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase())
                );
            }
            if (this.selectedPlatformTypes.length > 0) {
                fields = fields.filter((el) => {
                    for (const p of this.selectedPlatformTypes) {
                        if (el.platforms.includes(parseInt(p))) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            return fields;
        },
        platformTypeOptions() {
            if (
                this.bandaidMetadata == undefined ||
                this.bandaidMetadata.platformMap == undefined
            )
                return [];
            return Object.entries(this.bandaidMetadata.platformMap).map(
                ([key, value]) => {
                    return {
                        id: key,
                        text: value,
                    };
                }
            );
        },
        isWaypointsSelected() {
            return this.waypointId !== undefined;
        },
        bandaidMetadata() {
            return this.bandaidConfigMetadata;
        },
        metadata() {
            return this.configMetadata;
        },
        ...mapGetters("ConfigStore", [
            "bandaidConfigs",
            "bandaidConfigMetadata",
            "configStoreWaypointID",
            "configs",
            "configMetadata",
        ]),
    },
    async mounted() {
        await Promise.all([this.debounced_getMetadata()]);
        this.waypointId = this.configStoreWaypointID;
        this.stageBandaidConfig = clone(this.bandaidConfigs.stage);
        this.stageConfig = clone(this.configs.stage);

        if (
            this.waypointId != undefined &&
            (this.stageBandaidConfig == undefined ||
                this.stageConfig == undefined)
        )
            this.onLocationChange(this.waypointId);
    },
    created() {
        this.debounced_getMetadata = _.debounce(this.getMetadata);
        this.debounced_searchChange = _.debounce(this.onSearchChange);
    },
    methods: {
        getMetadata() {
            this.isMetadataLoading = true;
            Promise.all([
                this.getBandaidConfigMetadata(),
                this.getConfigMetadata(),
            ]).finally(() => {
                this.isMetadataLoading = false;
            });
        },
        onLocationChange(waypointId) {
            this.config = undefined;
            this.waypointId = waypointId;
            this.isConfigLoading = true;
            Promise.all([
                this.getBandaidConfigs(this.waypointId),
                this.getConfigs(this.waypointId),
            ]).finally(() => {
                this.isConfigLoading = false;
            });
        },
        onSearchChange(str) {
            this.searchString = str;
        },

        save() {
            this.isSaveInProgress = true;

            Promise.all([
                this.saveBandaidConfig({
                    storeWaypointID: this.waypointId,
                    config: this.stageBandaidConfig,
                }),
                this.saveConfigs({
                    storeWaypointID: this.waypointId,
                    config: this.stageConfig,
                }),
            ]).finally(() => {
                this.isSaveInProgress = false;
            });
        },
        publish() {
            this.isSaveInProgress = true;

            Promise.all([
                this.publishBandaidConfigs(this.waypointId),
                this.publishConfigs(this.waypointId)
            ]).finally(() => {
                this.isSaveInProgress = false;
            });
        },
        refresh() {
            this.onLocationChange(this.waypointId);
        },
        ...mapActions("ConfigStore", [
            "getBandaidConfigs",
            "getBandaidConfigMetadata",
            "saveBandaidConfig",
            "publishBandaidConfigs",
            "getConfigs",
            "getConfigMetadata",
            "saveConfigs",
            "publishConfigs",
        ]),
    },
    watch: {
        waypointId(value) {},
        bandaidConfigs() {
            this.stageBandaidConfig = clone(this.bandaidConfigs.stage);
        },
        configs() {
            this.stageConfig = clone(this.configs.stage);
        },
    },
};
</script>

<style lang="css" scoped>
.my-border {
    border: 2px solid black;
}
</style>
