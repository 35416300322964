var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: "Configs" },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-3", attrs: { width: "100%" } },
            [
              _c(
                "v-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { md: "8" } },
                    [
                      _c("store-search-new", {
                        attrs: {
                          airport: _vm.airport,
                          waypoint: _vm.waypointId,
                        },
                        on: {
                          "airport-change": function ($event) {
                            _vm.airport = $event
                          },
                          "waypoint-change": _vm.onLocationChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-0 mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-3 pl-1", attrs: { md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          attach: "",
                          label: "Search Configs",
                          clearable: "",
                        },
                        on: { change: _vm.debounced_searchChange },
                        model: {
                          value: _vm.searchString,
                          callback: function ($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "pr-3 pl-1", attrs: { md: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "overflow-y-auto mt-5",
                      attrs: { justify: "center" },
                    },
                    [
                      _c("v-col", [
                        !_vm.isWaypointsSelected
                          ? _c("h5", { staticClass: "pt-3" }, [
                              _vm._v(" Select a waypoint to start "),
                            ])
                          : _vm.filteredBandaidFields.length === 0
                          ? _c("h5", { staticClass: "pt-3" }, [
                              _vm._v(
                                " Sorry, no config items matches your query. "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm.bandaidMetadata.fields != undefined &&
                  _vm.bandaidMetadata.fields.length > 0 &&
                  _vm.configMetadata.fields != undefined &&
                  _vm.configMetadata.fields.length > 0 &&
                  _vm.waypointId != undefined
                    ? _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "fill-height pr-1",
                              attrs: { md: "6", sm: "8" },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v("Bandaid Configs"),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "fill-height",
                                          attrs: { md: "12" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("BandaidConfigForm", {
                                                attrs: {
                                                  loading:
                                                    _vm.isMetadataLoading ||
                                                    _vm.isConfigLoading ||
                                                    _vm.isSaveInProgress,
                                                  prodValue:
                                                    _vm.bandaidConfigs.prod,
                                                  metadata: _vm.bandaidMetadata,
                                                  fields:
                                                    _vm.filteredBandaidFields,
                                                  searchString:
                                                    _vm.searchString,
                                                  selectedPlatformTypes:
                                                    _vm.selectedPlatformTypes,
                                                  disabledKeys:
                                                    _vm.disabledKeys,
                                                  env: "stage",
                                                },
                                                model: {
                                                  value: _vm.stageBandaidConfig,
                                                  callback: function ($$v) {
                                                    _vm.stageBandaidConfig = $$v
                                                  },
                                                  expression:
                                                    "stageBandaidConfig",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "fill-height pl-1",
                              attrs: { md: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v("Configs (newer)"),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("ConfigForm", {
                                        attrs: {
                                          loading:
                                            _vm.isMetadataLoading ||
                                            _vm.isConfigLoading ||
                                            _vm.isSaveInProgress,
                                          metadata: _vm.metadata,
                                          fields: _vm.filteredFields,
                                          searchString: _vm.searchString,
                                          selectedPlatformTypes:
                                            _vm.selectedPlatformTypes,
                                          prodValue: _vm.configs.prod,
                                          disabledKeys: _vm.disabledKeys,
                                          env: "prod",
                                        },
                                        model: {
                                          value: _vm.stageConfig,
                                          callback: function ($$v) {
                                            _vm.stageConfig = $$v
                                          },
                                          expression: "stageConfig",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }