var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-col", { attrs: { cols: "12" } }, [
    _vm.loading || _vm.value == undefined
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          _vm._l(20, function (i) {
            return _c("v-skeleton-loader", {
              key: i,
              staticClass: "ma-2 d-flex flex-column",
              attrs: {
                type: "list-item",
                elevation: "2",
                width: "100%",
                height: "60px",
              },
            })
          }),
          1
        )
      : _c(
          "div",
          _vm._l(_vm.fields, function (field) {
            return _c(
              "field-row",
              {
                key: field.key,
                staticStyle: { height: "60px" },
                attrs: { "hide-line-number": "", title: "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { md: "12" } },
                  [
                    _c(
                      "v-row",
                      {
                        staticStyle: { "align-items": "center" },
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c("v-col", { attrs: { md: "10" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pr-1 font-weight-bold black--text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.labelForField(field)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "v-col",
                                  { attrs: { md: "1" } },
                                  [
                                    field.doc
                                      ? _c("text-tooltip", {
                                          attrs: { label: field.doc },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        field.type === "Boolean"
                          ? _c(
                              "v-col",
                              { staticClass: "pr-1", attrs: { md: "3" } },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    color: "#93BD20",
                                    dense: "",
                                    label: "Stage",
                                    "hide-details": "",
                                    disabled:
                                      _vm.disabled ||
                                      _vm.disabledKeys.includes(field.key),
                                  },
                                  model: {
                                    value: _vm.value[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, field.key, $$v)
                                    },
                                    expression: "value[field.key]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        field.type === "Boolean"
                          ? _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    color: "#93BD20",
                                    dense: "",
                                    label: "Production",
                                    "hide-details": "",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.prodValue[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.prodValue, field.key, $$v)
                                    },
                                    expression: "prodValue[field.key]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        ["String", "Double", "Int32"].indexOf(field.type) > -1
                          ? _c(
                              "v-col",
                              { staticClass: "pr-1", attrs: { md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    color: _vm.textFieldColorForField(field),
                                    light: "",
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    placeholder: field.placeholder,
                                    label: "Stage",
                                    type:
                                      field.type === "String"
                                        ? "text"
                                        : "number",
                                    disabled:
                                      _vm.disabled ||
                                      _vm.disabledKeys.includes(field.key),
                                  },
                                  model: {
                                    value: _vm.value[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, field.key, $$v)
                                    },
                                    expression: "value[field.key]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        ["String", "Double", "Int32"].indexOf(field.type) > -1
                          ? _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    color: _vm.textFieldColorForField(field),
                                    light: "",
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    placeholder: field.placeholder,
                                    label: "Production",
                                    type:
                                      field.type === "String"
                                        ? "text"
                                        : "number",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.value[field.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value, field.key, $$v)
                                    },
                                    expression: "value[field.key]",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }