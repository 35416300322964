<template>
    <v-col cols="12">
        <div v-if="loading || value == undefined" style="width: 100%">
            <v-skeleton-loader
                v-for="i in 20"
                :key="i"
                type="list-item"
                class="ma-2 d-flex flex-column"
                elevation="2"
                width="100%"
                height="60px"
            />
        </div>
        <div v-else>
            <v-row no-gutters v-for="field in fields" :key="field.key">
                <v-col md="12">
                    <v-row no-gutters style="align-items: center">
                        <v-col md="4">
                            <v-row no-gutters class="pb-0 pt-0">
                                <v-col md="10">
                                    <div
                                        class="pr-1 font-weight-bold black--text"
                                    >
                                        {{ labelForField(field) }}
                                    </div>
                                </v-col>
                                <v-col md="1">
                                    <text-tooltip
                                        v-if="field.doc"
                                        :label="field.doc"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pb-0 pt-0">
                                <v-col md="10">
                                    <div
                                        class="pr-1 font-weight-bold black--text"
                                    >
                                        <span
                                            v-for="p in field.platforms"
                                            :key="p"
                                            :style="`color:${platformColorMap[p]};`"
                                        >
                                            {{ platformShortNameMap[p] }}
                                        </span>
                                    </div>
                                </v-col></v-row
                            >
                        </v-col>
                        <v-col
                            md="3"
                            v-if="field.type === 'Boolean'"
                            class="pr-1"
                        >
                            <v-switch
                                color="#93BD20"
                                v-model="value[field.key]"
                                dense
                                label="Stage"
                                hide-details=""
                                :disabled="
                                    disabled || disabledKeys.includes(field.key)
                                "
                            >
                            </v-switch>
                        </v-col>
                        <v-col md="3" v-if="field.type === 'Boolean'">
                            <v-switch
                                color="#93BD20"
                                v-model="prodValue[field.key]"
                                dense
                                label="Production"
                                hide-details=""
                                :disabled="true"
                            >
                            </v-switch>
                        </v-col>
                        <v-col
                            md="3"
                            v-if="
                                ['String', 'Double', 'Int32'].indexOf(
                                    field.type
                                ) > -1
                            "
                            class="pr-1"
                        >
                            <v-text-field
                                :color="textFieldColorForField(field)"
                                v-model="value[field.key]"
                                light
                                dense
                                hide-details
                                outlined
                                :placeholder="field.placeholder"
                                label="Stage"
                                :type="
                                    field.type === 'String' ? 'text' : 'number'
                                "
                                :disabled="
                                    disabled || disabledKeys.includes(field.key)
                                "
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            md="3"
                            v-if="
                                ['String', 'Double', 'Int32'].indexOf(
                                    field.type
                                ) > -1
                            "
                        >
                            <v-text-field
                                :color="textFieldColorForField(field)"
                                v-model="value[field.key]"
                                light
                                dense
                                hide-details
                                outlined
                                :placeholder="field.placeholder"
                                label="Production"
                                :type="
                                    field.type === 'String' ? 'text' : 'number'
                                "
                                :disabled="true"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="field.type.includes('List')" md="3" class="pr-1">
                            <v-select
                                v-model="value[field.key]"
                                :items="languageItems"
                                attach
                                chips
                                dense
                                hide-details
                                small-chips
                                :color="textFieldColorForField(field)"
                                label="Stage"
                                multiple
                                outlined
                            >
                                <template #selection="{ item }">
                                    <v-chip :color="textFieldColorForField(field)" small text-color="white">{{
                                        item
                                    }}</v-chip>
                                </template></v-select
                            >
                        </v-col>
                                                <v-col v-if="field.type.includes('List')" md="3">
                            <v-select
                                v-model="prodValue[field.key]"
                                :items="languageItems"
                                attach
                                chips
                                dense
                                hide-details
                                small-chips
                                :color="textFieldColorForField(field)"
                                label="Production"
                                multiple
                                outlined
                                disabled
                            >
                                <template #selection="{ item }">
                                    <v-chip :color="textFieldColorForField(field)" small text-color="white">{{
                                        item
                                    }}</v-chip>
                                </template></v-select
                            >
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<script>
import _ from "lodash";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";
import FieldRow from "components/create_store/fields/FieldRow";
import TextTooltip from "components/text_tooltip";

export default {
    name: "ConfigForm",
    data() {
        return {
            platformColorMap: {
                TABLETOP: "#CC5500",
                VIRTUAL_KIOSK: "#D2042D",
            },
            platformShortNameMap: {
                TABLETOP: "OAT",
                VIRTUAL_KIOSK: "VK",
            },
            languageItems: [
                "en",
                "ar",
                "da",
                "es-mx",
                "fi",
                "fr",
                "ja",
                "no",
                "sv",
                "zh-cn",
                "zh-hans",
                "zh-tw",
            ],
        };
    },
    props: {
        env: {
            type: String,
            required: true,
        },
        metadata: {
            type: Object,
            required: true,
        },
        value: {
            type: Object,
        },
        prodValue: {
            type: Object,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        searchString: {
            type: String,
            required: true,
        },
        selectedPlatformTypes: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        disabledKeys: {
            type: Array,
            required: true,
        },
    },
    components: {
        RadioGroupRow,
        FieldRow,
        TextTooltip,
    },
    computed: {
        disabled() {
            return this.env == "prod";
        },
    },

    methods: {
        textFieldColorForField(field) {
            return "#93BD20";
        },
        labelForField(field) {
            return field.title;
        },
    },
    watch: {
        waypointId(value) {},
    },
};
</script>

<style lang="css" scoped>
.my-border {
    border: 2px solid black;
}
</style>
